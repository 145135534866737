import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/Seo'
import PriceListTemplate from '../components/PriceList/PriceListTemplate'

const NoPage = () => {
  const data = useStaticQuery(graphql`
    {
      allWpProduct(
        filter: { productDetails: { priceNo: { gt: 0 } } }
        sort: { fields: menuOrder, order: ASC }
      ) {
        nodes {
          brands {
            nodes {
              termTaxonomyId
            }
          }
          databaseId
          productDetails {
            year
            sku: skuNo
            price: priceNo
            origin
            literbottle
            alcoholcontent
            bottlebox
            vat
            incvat
            invoiceno
            epdno
          }
          title
          menuOrder
        }
      }
      wp {
        globalSettings {
          dates {
            norway {
              startdate
              enddate
            }
          }
          markets {
            markets {
              lang {
                alcoholcontent
                allbrand
                bottlebox
                contact
                downloadpdf
                literbottle
                origin
                price
                vat
                sku
                year
                title
              }
            }
          }
          password {
            passwordNo
          }
        }
      }
      file(name: { eq: "MH-LOGO-NORGE-gray-on-white-RGB-01" }) {
        id
        publicURL
      }
    }
  `)

  const { nodes } = data.allWpProduct
  const lang = data.wp.globalSettings.markets.markets
  const { passwordNo } = data.wp.globalSettings.password

  return (
    <>
      <SEO title="Norway" />
      <PriceListTemplate
        logo={data.file.publicURL}
        market="no"
        marketName="Norge"
        dates={data.wp.globalSettings.dates.norway}
        datelang="nn"
        products={nodes}
        language={lang[1].lang}
        password={passwordNo}
      />
    </>
  )
}

export default NoPage
